#Portfolio {
	font-family: "Roboto", sans-serif;
	height: 100vh;
	box-sizing: border-box;
	
	#back {
		text-decoration: underline;
		color: royalblue;
	}
	
	h1 {
		text-decoration: underline;
	}
	
	h2 {
		margin: 10px 0 20px;
	}
	
	.text {
		line-height: 2.2em;
	}
	
	.img {
		justify-self: center;
		align-self: center;
		max-height: 70vh;
		box-shadow: 5px 5px 10px gray;
	}
	
	#left-side {
		padding: 30px 0 50vh 60px;
		overflow-y: scroll;
		flex-direction: column;
		flex-grow: 1;
		align-items: center;
		#left-side-content {
			flex-direction: column;
			max-width: 900px;
			
			#tech-list {
				flex-wrap: wrap;
				
				.tech-item {
					margin: 0 0 20px 30px;
					flex-direction: column;
					cursor: pointer;
					width: 100px;
					align-items: center;
					
					div {
						height: 100px;
						width: 100px;
						background-position: center;
						background-size: 120%;
						border-radius: 10px;
						transition: background-size .5s;
					}
					
					p {
						margin: 10px 0 0;
						font-weight: 500;
					}
				}
				
				.tech-item:hover {
					div {
						background-size: 140%;
					}
				}
				
				.tech-item:hover {
					background-size: 140%;
				}
			}
			
			#proj-list {
				flex-direction: column;
				
				.proj-item {
					flex-direction: column;
					
					p {
						text-align: justify;
						line-height: 2em;
						
						a{
							word-break: break-word;
						}
					}
					
					.text-img {
						display: none;
						flex-direction: column;
						justify-content: center;
						
						img {
							max-width: 80%;
							justify-self: center;
						}
						
						.desc {
							font-size: 12px;
							font-weight: 500;
							text-align: center;
						}
					}
				}
			}
		}
	}
	#left-side::-webkit-scrollbar {
		display: none;
	}
	
	#right-side {
		width: 500px;
		flex-direction: column;
		height: 100%;
		flex-shrink: 0;
		
		#gallery {
			> div {
				flex-direction: column;
				width: 500px;
				position: absolute;
				align-items: center;
				justify-content: center;
				
				.desc {
					margin: 20px 0;
				}
			}
		}
	}
}


@media (max-width: 1000px) {
	#Portfolio {
		#left-side {
			padding-top: 30px;
			padding-right: 10px;
			padding-left: 10px;
		}
		
		#right-side {
			width: 400px;
			
			#gallery {
				> div {
					width: 400px;
				}
			}
		}
	}
}

@media (max-width: 800px) {
	#Portfolio {
		padding: 20px 20px;
		
		h1 {
			text-align: center;
		}
		
		.text {
			line-height: 1.5em
		}
		
		#left-side{
			#left-side-content {
				#tech-list{
					justify-content: space-around;
				}
				#proj-list {
					.proj-item {
						p {
							line-height: 1.5em;
						}
						
						.text-img {
							display: flex;
						}
					}
				}
			}
		}
		
		#right-side {
			display: none;
		}
	}
}
