body {
  margin: 0;
  display: flex;
}
div{
  display: flex;
}
#root{
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
}
body::-webkit-scrollbar {
  display: none;
}