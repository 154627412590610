#Links{
  width: 80%;
  align-self: center;
  max-width: 1000px;
  box-sizing: border-box;
  display: grid;
  justify-items: center;
  align-items: stretch;

  >div{
    height: 100%;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    a {
      height: 100%;
      width: 100%;
      color: black;
      text-decoration: none;
      cursor: pointer;
      > div {
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4);
        width: 100%;
        box-shadow: rgba(0, 0, 0, .36) 3px 3px 10px;
        border-radius: 5px;
      }
    }
  }
}